body {
    margin: 0;
    /* font-family: "Montserrat", sans-serif; */
    font-family: "Roboto Condensed", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 700;
    font-size: 16px;
}

a {
    text-decoration: none;
}

* {
    margin: 0;
}

.chip {
    background: gray;
    color: white;
    border: none;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
}

.chip-list {
    white-space: nowrap;
}

.chip--selected {
    background: black;
}

.table {
    display: flex;
    flex: 1;
    margin: 10px auto;
}

.content {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
    margin: 0 auto;
}

h2 {
    font-size: 180%;
    text-transform: uppercase;
}

td {
    padding: 3px 15px;
}

.header-line {
    opacity: 0.2;
}

.strava-logo {
    max-width: 150px;
}

li {
    line-height: 110%;
    padding-bottom: 8px;
}

ul {
    padding-inline-start: 24px;
}