* {
    margin: 0;
    font-family: "Roboto Condensed", sans-serif !important;
    font-weight: 700 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: rgba(0, 0, 0, 0.87);
}

html,
body {
    height: 100%;
}

body {
    display: flex;
}

#root {
    display: flex;
    flex: 1;
}

a {
    text-decoration: none;
}